<template>
  <AppList v-if="!insideList" class="recipient-category-list">
    <AppListItem
      :class="{
        'list-item--is-disabled': disabled,
      }"
      @itemClick="callback"
    >
      <template #figure>
        <AppFigure class="xe-figure" theme="gray">
          <slot name="figure-icon">
            <Component :is="iconImage" v-if="showIcon"></Component>
            <div v-else>
              {{ icon }}
            </div>
          </slot>
        </AppFigure>
      </template>

      <template #left>
        <AppListItemTitle>
          <h1 class="font-medium">
            {{ text }}
          </h1>
        </AppListItemTitle>
        <AppListItemCaption v-if="subTitle">{{ subTitle }} </AppListItemCaption>
        <slot name="error"></slot>
      </template>

      <template #action>
        <div class="flex flex-row items-center" v-if="showActionIcon">
          <AppSpinner v-if="isLoading" :loading="isLoading" />
          <AppIcon name=" " v-else class="recipient-action-icon">
            <IconChevronRight />
          </AppIcon>
        </div>
      </template>
    </AppListItem>
  </AppList>
  <AppListItem
    v-else
    :class="{
      'list-item--is-disabled': disabled,
    }"
    @itemClick="callback"
  >
    <template #figure>
      <AppFigure class="xe-figure" theme="gray">
        <slot name="figure-icon">
          <Component :is="iconImage" v-if="showIcon"></Component>
          <div v-else>
            {{ icon }}
          </div>
        </slot>
      </AppFigure>
    </template>

    <template #left>
      <AppListItemTitle>
        <h1 class="font-medium">
          {{ text }}
        </h1>
      </AppListItemTitle>
      <AppListItemCaption v-if="subTitle"> {{ subTitle }} </AppListItemCaption>
      <slot name="error"></slot>
    </template>

    <template #action>
      <div class="flex flex-row items-center" v-if="showActionIcon">
        <AppSpinner v-if="isLoading" :loading="isLoading" />
        <AppIcon name=" " v-else class="recipient-action-icon">
          <IconChevronRight />
        </AppIcon>
      </div>
    </template>
  </AppListItem>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { IconChevronRight } from '@oen.web.vue2/icons'

import { ref } from '@vue/composition-api'

import {
  AppIcon,
  AppFigure,
  AppListItem,
  AppListItemTitle,
  AppListItemCaption,
  AppList,
  AppSpinner,
} from '@oen.web.vue2/ui'

export default {
  name: 'XeListItem',
  components: {
    AppList,
    AppListItemCaption,
    AppListItemTitle,
    AppListItem,
    AppIcon,
    AppFigure,
    IconChevronRight,
    AppSpinner,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    select: {
      type: Function,
      required: false,
      default: () => {},
    },
    icon: {
      type: String,
      required: false,
    },
    insideList: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showActionIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit, listeners }) {
    const { $t } = useI18nStore()
    let iconImage = props.icon
    let showIcon = true
    if (props.icon) {
      let icons = require('@oen.web.vue2/icons/')
      iconImage = icons[iconImage]
      if (!iconImage) {
        iconImage = props.icon
        showIcon = false
      }
    }
    const isLoading = ref(false)
    const callback = () => {
      isLoading.value = true
      if (listeners.select) {
        emit('select')
      } else {
        props.select()
      }
      setTimeout(() => {
        isLoading.value = false
      }, 1500)
    }
    return { $t, iconImage, showIcon, isLoading, callback }
  },
}
</script>
<style scoped>
.recipients-list-title {
  @apply mb-3;
  @apply tracking-wider;
}

.recipient-category-list {
  @apply border-t-0 border-b-0;
}

/* fixing hover for applist*/
::v-deep .recipient-category-list .list-item-caption {
  @apply text-xs font-normal  text-tertiary-text;
}

::v-deep .list-item-title {
  @apply font-medium text-base;
  color: var(--theme-text, var(--theme-color-secondary, #333333));
}

::v-deep .list-item-caption {
  @apply text-xs font-normal text-tertiary-text;
}

.list-item--is-disabled {
  @apply cursor-not-allowed pointer-events-none opacity-50;
}

::v-deep .list-item-figure {
  /* TODO !!!!!!!!!!!!!! move to framework ? because of the inline-flex in <figure> it will add the lineheight to it so i.e. if <figure> is 40px .list-item-figure will be 47px */
  line-height: 0px;
}
</style>
